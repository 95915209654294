import { IHotel, IStaffInfo, PatchedTypes } from "messages";
import {
  BotMessage,
  DepartmentNotification,
  GuestChatHandled,
  GuestChatRequest,
  GuestChatRequestTimeout,
  GuestChatSession,
  PeerMessage,
  Property,
  PropertyNotification,
  Staff,
  StaffNotification,
  StaffOnline,
  StaffRoster
} from "messaging";
import { autorun } from "mobx";
import { inject } from "mobx-react";
import React, { Component, Context, createContext } from "react";
import { ActiveChat, BotChat, HistoryChat } from "../models";
import { ApiService, GuestChatSessionPersistenceService } from "../services";
import { ClientStore, ToastStore } from "../stores";
import { getAddedSessions, getRemovedSessions } from "../utils";

export enum ONBOARDING {
  WELCOME = "welcome_user",
  WELCOME_BACK = "welcome_back",
  SERVICE_REQUEST_TIMEOUT = "welcome_after_timeout"
}

const StateContext: Context<IState> = createContext({} as IState);
const { Consumer, Provider } = StateContext;

export const getDepartmentsFromStaffInfo = ({ department, departmentAccess }: IStaffInfo): string[] => {
  const departments = [department, ...(Array.isArray(departmentAccess) ? departmentAccess : [])];
  return departments.filter((oneDepartment: string | null | undefined) => oneDepartment) as string[];
};

export type IState = IStateProviderState & {
  createActiveChat: (session: GuestChatSession) => ActiveChat;
  createHistoryChat: (session: GuestChatSession) => HistoryChat;
  dismissActiveRequest: (chreqid: string) => void;
  initStateContext: (tid: string, pid: string, sfid: string) => void;
  markAllDashboardNotificationsAsRead: () => void;
  markAllMyRequestNotificationsAsRead: () => void;
  setChatOpenOnUI: (sesid: string) => void;
  setHotelInfo: (tid: string, pid: string) => void;
  setIsDashboardOpenOnUI: (isOpen: boolean) => void;
  setIsMyRequestsOpenOnUI: (isOpen: boolean) => void;
  setProperty: (property: string) => void;
  setPropertyId: (pid: string) => void;
  setStaff: (staff: string) => void;
  setStaffClientSerivceError: (error: Error) => void;
  setAllStaffInfo: () => void;
  setStaffId: (sfid: string) => void;
  setStatus: (status: string) => void;
  setTenantId: (tid: string) => void;
  staffInfo?: IStaffInfo;
  subscribeToTopics: (staff: Staff) => void;
  sendToast: (header: string, subheader: string) => void;
};

interface IStateProviderState {
  activeChats: Map<string, ActiveChat>;
  botChat: BotChat;
  activeRequests: GuestChatRequest[];
  allStaffInfoMap: Map<string, IStaffInfo>;
  chatOpenOnUI: string;
  hotelInfo?: IHotel;
  dashboardNotifications: Set<string>;
  histories: Map<string, GuestChatSession>;
  historyChats: Map<string, HistoryChat>;
  isDashboardOpenOnUI: boolean;
  isMyRequestsOpenOnUI: boolean;
  isRequestsLoading: boolean;
  isSubscribedToBotTopics: boolean;
  myRequestNotifications: Set<string>;
  pid: string;
  property?: Property;
  serviceRequests: Map<string, PatchedTypes.IServiceRequest>;
  sessions: Map<string, GuestChatSession>;
  staff?: Staff;
  staffClientServiceError?: Error;
  sfid: string;
  status: string;
  tid: string;
}

interface IStateProviderProps {
  staffInfo?: IStaffInfo;
  clientStore?: ClientStore;
  toastStore?: ToastStore;
}

@inject("clientStore", "toastStore")
class StateProvider extends Component<IStateProviderProps, IStateProviderState> {
  public state: IStateProviderState = {
    activeChats: new Map(),
    activeRequests: [],
    allStaffInfoMap: new Map(),
    botChat: new BotChat(),
    chatOpenOnUI: "",
    dashboardNotifications: new Set(),
    histories: new Map(),
    historyChats: new Map(),
    isDashboardOpenOnUI: false,
    isMyRequestsOpenOnUI: false,
    isRequestsLoading: true,
    isSubscribedToBotTopics: false,
    myRequestNotifications: new Set(),
    pid: "",
    serviceRequests: new Map(),
    sessions: new Map(),
    sfid: "",
    status: "offline",
    tid: ""
  };

  public notificationTone = new Audio("/media/plucky.mp3");

  constructor(props: IStateProviderProps) {
    super(props);

    this.subscribeToDepartmentNotificationTopics = this.subscribeToDepartmentNotificationTopics.bind(this);
    this.subscribeToTopics = this.subscribeToTopics.bind(this);

    const tid = localStorage.getItem("TID");
    const pid = localStorage.getItem("PID");
    const sfid = localStorage.getItem("SFID");

    if (tid && pid && sfid) {
      this.initStateContext(tid, pid, sfid);
    }
  }

  public initStateContext = (tid: string, pid: string, sfid: string) => {
    const staff = Staff.fromString(`t/${tid}/p/${pid}/sf/${sfid}`);
    const property = Property.fromString(`t/${tid}/p/${pid}`);
    GuestChatSessionPersistenceService.historySessions(tid, pid, sfid).then((histories: GuestChatSession[]) => {
      histories.map((session: GuestChatSession) => {
        this.state.histories.set(session.sesid, session);
      });
    });
    this.state.staff = staff;
    this.state.property = property;
    this.state.tid = tid;
    this.state.pid = pid;
    this.state.sfid = sfid;
    this.setHotelInfo();
  };

  public shouldComponentUpdate(nextProps: {}, nextState: IStateProviderState) {
    const { staffClientServiceError } = nextState;
    if (staffClientServiceError) {
      throw staffClientServiceError;
    }
    return true;
  }

  public getSnapshotBeforeUpdate(prevProps: IStateProviderProps) {
    if (!prevProps.staffInfo && this.props.staffInfo) {
      return { shouldFetchServiceRequests: true };
    }
    return null;
  }

  public async componentDidUpdate(
    prevProps: IStateProviderProps,
    prevState: IStateProviderState,
    snapshot?: {
      shouldFetchServiceRequests?: boolean;
    }
  ) {
    if (snapshot && snapshot.shouldFetchServiceRequests) {
      const { pid, tid } = this.props.staffInfo!;
      const { staff } = this.state;
      const departments = getDepartmentsFromStaffInfo(this.props.staffInfo!);

      this.setState({ isRequestsLoading: true });
      const serviceRequests = new Map();
      const requests = await ApiService.getServiceRequests(tid, pid, departments);
      requests.map((serviceRequest: PatchedTypes.IServiceRequest) =>
        serviceRequests.set(serviceRequest.serreqid, serviceRequest)
      );

      this.setState({ serviceRequests, isRequestsLoading: false });

      autorun(
        () => {
          if (this.props.clientStore!.isConnectedToMQTTBroker) {
            this.subscribeToTopics(staff!);
            this.subscribeToDepartmentNotificationTopics(departments, pid, tid);
          }
        },
        { onError: error => console.log(error) }
      );
    }
  }

  public subscribeToDepartmentNotificationTopics = async (departments: string[], pid: string, tid: string) => {
    const subscribeToDepartmentNotifications = departments.map(async (department: string) => {
      const client = this.props.clientStore!.getClient();
      if (client) {
        return client.acceptDepartmentNotification(
          Property.fromString(`t/${tid}/p/${pid}`),
          this.handleNotification,
          `/${department}`
        );
      }
    });

    Promise.all(subscribeToDepartmentNotifications).catch((error: Error) => console.log(error));
  };

  public createActiveChat = (session: GuestChatSession) => {
    const { sesid, guest } = session;
    const { activeChats } = this.state;
    const chat = new ActiveChat(sesid, guest);
    activeChats.set(sesid, chat);
    this.setState({ activeChats });
    return chat;
  };

  public createHistoryChat = (session: GuestChatSession) => {
    const { sesid } = session;
    const { activeChats, historyChats, sessions, histories } = this.state;
    const chat = activeChats.get(sesid);
    if (chat) {
      chat.complete();
    }
    const historyChat = chat ? new HistoryChat({ chat }) : new HistoryChat({ session });
    histories.set(sesid, session);
    historyChats.set(sesid, historyChat);
    sessions.delete(sesid);
    activeChats.delete(sesid);
    this.setState({ histories });
    this.setState({ historyChats });
    this.setState({ sessions });
    this.setState({ activeChats });
    return historyChat;
  };

  public dismissActiveRequest = (chreqid: string) => {
    const { activeRequests } = this.state;
    this.setState({
      activeRequests: activeRequests.filter((request: GuestChatRequest) => request.chreqid !== chreqid)
    });
  };

  public markAllDashboardNotificationsAsRead = () => {
    this.setState({ dashboardNotifications: new Set() });
  };

  public markAllMyRequestNotificationsAsRead = () => {
    this.setState({ myRequestNotifications: new Set() });
  };

  public setChatOpenOnUI = (sesid: string) => {
    this.setState({ chatOpenOnUI: sesid });
  };

  public setIsDashboardOpenOnUI = (isDashboardOpenOnUI: boolean) => {
    this.setState({ isDashboardOpenOnUI });
  };

  public setIsMyRequestsOpenOnUI = (isMyRequestsOpenOnUI: boolean) => {
    this.setState({ isMyRequestsOpenOnUI });
  };

  public setTenantId = (tid: string) => {
    this.setState({ tid });
  };

  public setProperty = (property: string) => {
    this.setState({ property: Property.fromString(property) });
  };

  public setPropertyId = (pid: string) => {
    this.setState({ pid });
  };

  public setStaff = (staff: string) => {
    this.setState({ staff: Staff.fromString(staff) });
  };

  public setStaffClientServiceError = (error: Error) => {
    this.setState({ staffClientServiceError: error });
  };

  public setStaffId = (sfid: string) => {
    this.setState({ sfid });
  };

  public setStatus = (status: string) => {
    this.setState({ status });
  };

  public subscribeToTopics = async (staff: Staff) => {
    const { id: sfid, property } = staff;
    const staffClient = this.props.clientStore!.getClient();

    if (staffClient) {
      staffClient.acceptPropertyNotification(staff.property, this.handleNotification);
      staffClient.acceptStaffNotification(staff, this.handleNotification);
      staffClient.acceptStaffOnline(staff, this.handleStaffOnline);
      staffClient.acceptGuestChatRequest(staff, this.handleGuestChatRequest);
      staffClient.acceptGuestChatHandled(staff, this.handleGuestChatHandled);
      staffClient.acceptStaffRoster(staff, this.handleStaffRoster);
      staffClient.acceptGuestChatRequestTimeout(staff, this.handleGuestChatRequestTimeout);
      staffClient
        .acceptBotMessage(property, this.handleBotMessage, `/${sfid}`)
        .then(() => this.setState({ isSubscribedToBotTopics: true }));
    }
  };

  public sendToast = async (title: string, body: string) => {
    const { closeToast, openToast } = this.props.toastStore!;
    closeToast();
    openToast({ title, body });
  };

  public setHotelInfo = async () => {
    const hotelInfo: IHotel = await ApiService.getHotelInformation(this.state.tid, this.state.pid);
    const time = new Date().getTime();
    this.setState({ hotelInfo });
  };
  public setAllStaffInfo = async () => {
    if (this.state.allStaffInfoMap && this.state.allStaffInfoMap.size > 1) {
      return;
    }
    const allStaffInfo = await ApiService.getAllStaffInfo(this.state.tid, this.state.pid);
    const staffInfoMap = new Map<string, IStaffInfo>();

    allStaffInfo.forEach((staff: IStaffInfo) => {
      staffInfoMap.set(staff.sfid, staff);
    }, {});
    this.setState({
      allStaffInfoMap: staffInfoMap
    });
  };

  public render() {
    return (
      <Provider
        value={{
          ...this.state,
          createActiveChat: this.createActiveChat,
          createHistoryChat: this.createHistoryChat,
          dismissActiveRequest: this.dismissActiveRequest,
          initStateContext: this.initStateContext,
          markAllDashboardNotificationsAsRead: this.markAllDashboardNotificationsAsRead,
          markAllMyRequestNotificationsAsRead: this.markAllMyRequestNotificationsAsRead,
          sendToast: this.sendToast,
          setAllStaffInfo: this.setAllStaffInfo,
          setChatOpenOnUI: this.setChatOpenOnUI,
          setHotelInfo: this.setHotelInfo,
          setIsDashboardOpenOnUI: this.setIsDashboardOpenOnUI,
          setIsMyRequestsOpenOnUI: this.setIsMyRequestsOpenOnUI,
          setProperty: this.setProperty,
          setPropertyId: this.setPropertyId,
          setStaff: this.setStaff,
          setStaffClientSerivceError: this.setStaffClientServiceError,
          setStaffId: this.setStaffId,
          setStatus: this.setStatus,
          setTenantId: this.setTenantId,
          staffInfo: this.props.staffInfo,
          subscribeToTopics: this.subscribeToTopics
        }}
      >
        {this.props.children}
      </Provider>
    );
  }

  private acceptMessage = async ({ sesid, property }: GuestChatSession) => {
    const staffClient = this.props.clientStore!.getClient();
    if (staffClient) {
      staffClient.acceptMessage(Property.fromString(property), this.handleMessage, `/${sesid}${PeerMessage.suffix}`);
    }
  };

  private unacceptMessage = async ({ sesid, property }: GuestChatSession) => {
    const staffClient = this.props.clientStore!.getClient();
    if (staffClient) {
      staffClient.unacceptMessage(Property.fromString(property), `/${sesid}${PeerMessage.suffix}`);
    }
  };

  private addMessageToChat = (message: PeerMessage) => {
    const { activeChats, chatOpenOnUI } = this.state;
    const { sesid, sender } = message;
    const chat = activeChats.get(sesid) || new ActiveChat(sesid, sender);
    if (chatOpenOnUI === sesid) {
      chat.addMessage(message);
    } else {
      chat.addUnreadMessage(message);
    }
    activeChats.set(sesid, chat);
    this.setState({ activeChats });
  };

  private handleBotMessage = (message: BotMessage) => {
    const { text } = message;
    if (
      text !== ONBOARDING.WELCOME &&
      text !== ONBOARDING.WELCOME_BACK &&
      text !== ONBOARDING.SERVICE_REQUEST_TIMEOUT
    ) {
      const { botChat } = this.state;
      const chat = botChat;
      chat.addMessage(message);
      this.setState({ botChat: chat });
    }
  };

  private handleGuestChatHandled = (handled: GuestChatHandled) => {
    this.dismissActiveRequest(handled.chreqid);
  };

  private handleGuestChatRequest = (request: GuestChatRequest) => {
    const { activeRequests } = this.state;
    this.setState({ activeRequests: [request, ...activeRequests] });
  };

  private handleGuestChatRequestTimeout = (message: GuestChatRequestTimeout) => {
    this.dismissActiveRequest(message.chreqid);
  };

  private handleMessage = (message: PeerMessage) => {
    this.addMessageToChat(message);
    this.sendNewMessageToast(message);
  };

  private handleNotification = (message: PropertyNotification | StaffNotification | DepartmentNotification) => {
    const {
      data: { request: newRequest }
    } = message;
    if (newRequest) {
      const {
        dashboardNotifications,
        isDashboardOpenOnUI,
        isMyRequestsOpenOnUI,
        myRequestNotifications,
        serviceRequests,
        sfid
      } = this.state;

      const { serreqid, status, staffs } = newRequest;
      const isSelfRequest = staffs && staffs[0] && staffs[0].sfid === sfid;
      let shouldPlayNotificationTone: boolean = false;

      if (status !== "COMPLETED") {
        if (isDashboardOpenOnUI) {
          setTimeout(() => {
            dashboardNotifications.delete(serreqid);
            this.setState({ dashboardNotifications });
          }, 10000);
        }
        dashboardNotifications.add(serreqid);
        shouldPlayNotificationTone = true;
      }

      if (status === "COMPLETED") {
        dashboardNotifications.delete(serreqid);
      }

      if (status !== "NEW" && isSelfRequest) {
        if (isMyRequestsOpenOnUI) {
          setTimeout(() => {
            myRequestNotifications.delete(serreqid);
            this.setState({ myRequestNotifications });
          }, 10000);
        }

        myRequestNotifications.add(serreqid);
        shouldPlayNotificationTone = true;
      }

      if (shouldPlayNotificationTone) {
        this.notificationTone.play();
      }
      serviceRequests.set(serreqid, newRequest as PatchedTypes.IServiceRequest);
      this.setState({ serviceRequests, dashboardNotifications, myRequestNotifications });
    }
  };

  private handleStaffOnline = (online: StaffOnline) => {
    const { isOnline } = online;
    this.setState({ status: isOnline ? "online" : "offline" });
  };

  private handleStaffRoster = (roster: StaffRoster) => {
    const added = getAddedSessions(this.state.sessions, roster.sessions);
    added.forEach(async (session: GuestChatSession) => {
      this.acceptMessage(session);
    });

    const removed = getRemovedSessions(this.state.sessions, roster.sessions);
    removed.forEach((session: GuestChatSession) => {
      this.unacceptMessage(session);
      this.createHistoryChat(session);
    });

    if (
      this.state.sessions.size > 0 &&
      roster.sessions.length > 0 &&
      this.state.sessions.size === roster.sessions.length &&
      added.length === 0 &&
      removed.length === 0
    ) {
      console.log("should see this log only when a staff mqtt client reconnects whilst mqtt broker/server was live");
      this.state.sessions.forEach((session: GuestChatSession) => {
        this.acceptMessage(session);
      });
    }

    const sessions = new Map();
    roster.sessions.forEach((session: GuestChatSession) => sessions.set(session.sesid, session));
    this.setState({ sessions });
  };

  private sendNewMessageToast = (message: PeerMessage) => {
    const { chatOpenOnUI, sessions } = this.state;
    const { text: body, sesid } = message;
    const session = sessions.get(sesid) as GuestChatSession;
    const { booking, bookingNo } = session;
    if (chatOpenOnUI !== sesid) {
      const { closeToast, openToast } = this.props.toastStore!;
      const title =
        booking && booking.firstName && booking.lastName
          ? `${booking.firstName} ${booking.lastName}`
          : booking && booking.roomNo
          ? booking.roomNo
          : bookingNo;
      closeToast();
      openToast({ title, body });
    }
  };
}

export { StateProvider, Consumer as StateConsumer, StateContext };
